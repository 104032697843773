<template>
  <v-container>
    <v-row justify="center" class="mt-8">
      <v-col lg="8" md="8" sm="12">
        <v-alert :type="message.type" v-model="message.show" closable>{{
          message.text
        }}</v-alert>

        <v-card elevation="6">
          <v-form ref="loginForm" @submit.prevent="createUser">
            <v-card-text>
              <h1>Cadastrar novo usuário</h1>
              <p class="mb-4">
                Preencha os campos abaixo para criar um novo usuário
              </p>

              <v-row dense>
                <v-col>
                  <v-text-field
                    label="Nome"
                    v-model="user.name"
                    :rules="[rules.required]"
                    class="mt-3"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-text-field
                    label="E-mail"
                    v-model="user.email"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-text-field
                    type="password"
                    label="Senha"
                    v-model="user.password"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row dense>
                <v-col>
                  <v-btn variant="text" size="x-small" to="/">
                    Voltar para a tela de login</v-btn
                  >
                </v-col>
                <v-col class="text-right">
                  <CustomBtn
                    type="submit"
                    mdi="mdi-content-save"
                    :loading="is_loading"
                    >Criar novo usuário</CustomBtn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import { defineComponent } from "vue";
import CustomBtn from "@/components/CustomBtn.vue";
import httpRequest from "@/routines/requests"

export default defineComponent({
  components: {CustomBtn},
  name: "RegistrationView",
  data: () => {
    return {
      is_loading: false,
      user: {
        name: "",
        email: "",
        password: "",
      },
      message: {
        type: "error",
        text: "",
        show: false,
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
    };
  },
  methods: {
    async createUser() {

      this.is_loading = true
      this.message.show = false;
      this.message.type = "error";

      let response = await httpRequest('register', 'POST', this.user, false, true);

        this.message.text = response.message;
        this.message.show = true;
        this.message.type = 'success';

        setTimeout(() => {
          this.is_loading = false;
        }, 2000);
      }
    },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-title-header {
  color: white;
  background: #7474bf; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #348ac7,
    #7474bf
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #348ac7,
    #7474bf
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
