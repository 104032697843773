import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";

import httpRequest from "@/routines/requests";
import RegistrationView from "@/views/RegistrationView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: LoginView,
  },
  {
    path: "/register",
    name: "registration",
    component: RegistrationView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dash" */ "../views/dashboard/DashMain.vue"),
    redirect: {
      name: "principal",
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        alias: "",
        path: "principal",
        name: "principal",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/DashHome.vue"
          ),
      },
      {
        path: "properties",
        name: "properties",
        component: () =>
          import(
            /* webpackChunkName: "property" */ "@/views/dashboard/properties/PropertiesView.vue"
          ),
      },
      {
        path: "properties-edit/:id",
        name: "properties-edit",
        component: () =>
          import(
            /* webpackChunkName: "property-edit" */ "@/views/dashboard/properties/PropertiesView.vue"
          ),
      },
      {
        path: "cities",
        name: "cities",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/cities/CitiesView.vue"
          ),
      },
      {
        path: "cities/:id",
        name: "cities-edit",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/cities/CitiesView.vue"
          ),
      },
      {
        path: "categories",
        name: "categories",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/categories/CategoriesView.vue"
          ),
      },
      {
        path: "categories/:id",
        name: "categories-edit",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/categories/CategoriesView.vue"
          ),
      },
      {
        path: "amenities",
        name: "amenities",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/amenities/AmenitiesView.vue"
          ),
      },
      {
        path: "amenities/:id",
        name: "amenities-edit",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/amenities/AmenitiesView.vue"
          ),
      },
      {
        path: "listing",
        name: "listing",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/listing/ListingView.vue"
          ),
      },
      {
        path: "listing/:id",
        name: "listing-edit",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/listing/ListingView.vue"
          ),
      },
      {
        path: "galleries",
        name: "galleries",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/galleries/GalleriesView.vue"
          ),
      },
      {
        path: "galleries/:id",
        name: "galleries-edit",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/galleries/GalleriesView.vue"
          ),
      },
      {
        path: "building-type",
        name: "building-type",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/building_types/BuildingTypesView.vue"
          ),
      },
      {
        path: "building-type/:id",
        name: "building-type-edit",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/building_types/BuildingTypesView.vue"
          ),
      },
      {
        path: "users",
        name: "users",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/users/UserView.vue"
          ),
      },
      {
        path: "cities/:id",
        name: "cities-edit",
        component: () =>
          import(
            /* webpackChunkName: "dash" */ "@/views/dashboard/cities/CitiesView.vue"
          ),
      },
      {
        path: "property-owners",
        name: "property-owners",
        component: () =>
          import(
            /* webpackChunkName: "property-owners" */ "@/views/dashboard/properties/PropertyOwnersView.vue"
          ),
      },
      {
        path: "property-owners-list",
        name: "property-owners-list",
        component: () =>
          import(
            /* webpackChunkName: "property-owners-list" */ "@/views/dashboard/properties/PropertyOwnersListView.vue"
          ),
      },
      {
        path: "property-owner/:id",
        name: "property-owners-edit",
        component: () =>
          import(
            /* webpackChunkName: "property-owners" */ "@/views/dashboard/properties/PropertyOwnersView.vue"
          ),
      },
      // {
      //   path: "properties-edit/:id",
      //   name: "properties-edit",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "property-edit" */ "@/views/dashboard/properties/PropertiesView.vue"
      //     ),
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const token = localStorage.getItem("token");

  if (requiresAuth && token === null) {
    next("/");
  } else if (requiresAuth && token != null) {
    let response = await httpRequest("refresh", "POST", "", true);

    if (response.status != 200) {
      localStorage.removeItem("token");
      router.push("/");
    } else {
      let data = await response.json();

      localStorage.setItem("token", data.authorization.token);
    }

    next();
  } else next();
});

export default router;
