<template>
  <v-btn :prepend-icon="mdi" :type="type" :loading="loading">
    <slot></slot>

    <template v-slot:loader>
      <v-progress-circular indeterminate></v-progress-circular>
    </template>
  </v-btn>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomBtn",
  props: {
    type: String,
    mdi: String,
    loading: Boolean,
  },
});
</script>
