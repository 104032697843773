<template>
  <v-container>
    <v-row justify="center" class="mt-8">
      <v-col lg="7" md="8" sm="12">
        <v-card elevation="6">
          <v-img
            class="text-white"
            :src="require('../assets/background.png')"
            height="300"
            cover
          ></v-img>

          <v-alert :type="message.type" v-model="message.show" closable>{{
            message.text
          }}</v-alert>

          <v-form ref="loginForm" @submit.prevent="userLogin">
            <v-card-text>
              <h1>Bem vindo de volta</h1>
              <p class="mt-2 mb-4">Favor, faça o login com sua conta.</p>

              <v-row dense>
                <v-col>
                  <v-text-field
                    label="E-mail"
                    v-model="user.email"
                    :rules="[rules.required]"
                    class="mt-3"
                    prepend-inner-icon="mdi-email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-text-field
                    type="password"
                    label="Senha"
                    v-model="user.password"
                    :rules="[rules.required]"
                    prepend-inner-icon="mdi-lock"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row dense>
                <v-col> </v-col>
                <v-col class="text-right">
                  <CustomBtn type="submit" mdi="mdi-login" :loading="is_loading"
                    >Acessar o painel</CustomBtn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import { defineComponent } from "vue";
import CustomBtn from "@/components/CustomBtn.vue";
import httpRequest from "@/routines/requests"

export default defineComponent({
  components: {CustomBtn},
  name: "LoginView",
  data: () => {
    return {
      is_loading: false,
      user: {
        email: "",
        password: "",
      },
      message: {
        type: "error",
        text: "",
        show: false,
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
    };
  },
  methods: {
    async userLogin() {

      this.is_loading = true
      this.message.show = false;
      this.message.type = "error";

      let response = await httpRequest('login', 'POST', this.user, false);
      if(response.status == 200) {
        const resp = await response.json();
          if (resp.authorization != undefined) {
            const userToken = resp.authorization.token;

            localStorage.setItem("token", userToken);
          }

          setTimeout(() => {
            if (resp.authorization != undefined) {
              this.$router.push("dashboard");
            }
          }, 2000);
      } else {
        const resp = await response.json();

        this.user.email = "";
        this.user.password = "";

        this.message.text = resp.message;
        this.message.type = 'error';
        this.message.show = true;


        setTimeout(() => {
          this.is_loading = false;
        }, 2000);
      }
    },
  },
  mounted() {
    document.title = "Painel administrativo"
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-title-header {
  color: white;
  background: #7474bf; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #348ac7,
    #7474bf
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #348ac7,
    #7474bf
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
