import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Vuetify
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import { pt } from "vuetify/locale";

import { aliases, mdi } from "vuetify/iconsets/mdi";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: "pt",
    fallback: "en",
    messages: { pt },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#1c71d8",
          secondary: "#92dafe",
        },
      },
    },
  },
  defaults: {
    VBtn: {
      variant: "elevated",
    },
    VCard: {
      VCardActions: {
        VBtn: {
          color: "primary",
          variant: "elevated",
        },
      },
    },
    VBtnGroup: {
      VBtn: {
        variant: "text",
        color: "white",
      },
    },
    VTextField: {
      variant: "outlined",
      density: "compact",
    },
    VCheckbox: {
      density: "compact",
    },
    VTextarea: {
      variant: "outlined",
      density: "compact",
    },
    VSelect: {
      variant: "outlined",
      density: "compact",
    },
    VRow: {
      dense: true,
    },
    VCombobox: {
      variant: "outlined",
      density: "compact",
    },
  },
});

createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .use(QuillEditor)
  .mount("#app");
