async function httpRequest(url, method, data, use_token = false, json = false) {
  let request_config = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (data != "") {
    request_config.body = JSON.stringify(data);
  }

  if (use_token) {
    const token = localStorage.getItem("token");
    request_config.headers.Authorization = `Bearer ${token}`;
  }

  let response = await fetch(process.env.VUE_APP_REMOTE_URL + url, {
    ...request_config,
  }).then((response) => {
    return response;
  });

  if (json) return response.json();
  else return response;
}

export default httpRequest;
