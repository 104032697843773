<template>
  <router-view />
</template>

<style lang="css">
html {
  min-height: 100%;
}
body {
  background: #757f9a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #d7dde8,
    #757f9a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #d7dde8,
    #757f9a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
